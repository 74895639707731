import {
  Typography,
  Breadcrumb,
  Col,
  Row,
  Tag,
  Descriptions,
  Table,
  Tabs,
  Button,
  Modal,
  Input,
  Alert,
  Upload,
  Select,
  message,
  Spin,
  Form,
} from "antd";
import { useState, useEffect } from "react";
import ProfileCard from "./ProfileCard";
import {
  getPrinters,
  fetchProfiles,
  uploadProfile,
} from "../../../Services/api";
import { UploadOutlined } from "@ant-design/icons";
import axios from "axios";

const { Title, Text } = Typography;
const { TextArea } = Input;

const SlicerProfiles = ({ filament }) => {
  const [open, setOpen] = useState(false);
  const [printers, setPrinters] = useState([]);
  const [selectedPrinter, setSelectedPrinter] = useState("");
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [file, setFile] = useState(null);
  const [profiles, setProfiles] = useState([]);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async (values) => {
    if (!file) {
      message.error("Please select a file to upload");
      return;
    }

    const formData = new FormData();
    setLoading(true);
    formData.append("file", file);
    formData.append("ProfileName", values.profile_name);
    formData.append("Description", values.profile_description);
    formData.append("printerBrand", values.printer_brand);
    formData.append("printerModel", values.printer_model);
    formData.append("SlicerName", values.slicer_name);
    formData.append("FilamentID", filament._id);
    formData.append("Filename", file.name);

    try {
      const status = await uploadProfile(formData);
      if (status === 200) {
        message.success("File uploaded successfully!");
      } else {
        message.error("Error uploading file");
      }
    } catch (error) {
      message.error("Error uploading file");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPrinters().then(setPrinters);
    fetchProfiles(filament._id).then(setProfiles);
  }, [filament._id]);

  const tabItems = [
    { key: "1", label: "Cura", slicer: "Cura" },
    { key: "2", label: "BambuStudio", slicer: "BambuStudio" },
    { key: "3", label: "PrusaSlicer", slicer: "PrusaSlicer" },
    { key: "4", label: "Simplify3D", slicer: "Simplify3D" },
    { key: "5", label: "Slic3r", slicer: "Slic3r" },
  ];

  const openUpload = () => {
    setOpen(true);
  };

  const handleOk = () => {
    if (!file) {
      message.error("Please select a file to upload");
      return;
    }
    setOpen(false);
    form.submit();
  };

  const handleSelectPrinter = (value) => {
    setSelectedPrinter(value);
  };

  return (
    <>
      <div
        style={{
          margin: "1% 0% 20%",
          padding: "5px 25px 50px",
          backgroundColor: "white",
          boxShadow: "0 0 4px rgba(0,0,0,0.2)",
          borderRadius: 5,
          width: "49%",
          float: "left",
        }}
      >
        <Title level={2} style={{ marginTop: 15 }}>
          Slicer Profiles
        </Title>

        <Text>
          Find working {filament.brandName} {filament.matType} slicer setting
          profiles and settings for your printer
        </Text>
        <br />
        <br />

        <Button type="primary" onClick={openUpload}>
          Upload Profile
        </Button>

        <Modal
          open={open}
          title="Upload New Printer Profile"
          onCancel={() => setOpen(false)}
          onOk={handleOk}
        >
          <Text>
            Thank you for your interest in uploading a printer profile for{" "}
            {filament.brandName} {filament.matType}. Please fill out the form
            below to upload your profile.
          </Text>
          <br />
          <br />
          <Form onFinish={handleUpload} form={form}>
            <Form.Item label="Profile Name" name="profile_name">
              <Input placeholder="Profile Name" />
            </Form.Item>

            <Form.Item label="Slicer" name="slicer_name">
              <Select
                defaultValue="Select Slicer"
                onChange={handleSelectPrinter}
              >
                <Select.Option value="Cura">Cura</Select.Option>
                <Select.Option value="BambuStudio">BambuStudio</Select.Option>
                <Select.Option value="PrusaSlicer">PrusaSlicer</Select.Option>
                <Select.Option value="Simplify3D">Simplify3D</Select.Option>
                <Select.Option value="Slic3r">Slic3r</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item label="Description" name="profile_description">
              <TextArea rows={4} placeholder="Profile Description" />
            </Form.Item>

            <Form.Item label="Upload File">
              <Upload
                beforeUpload={() => false}
                onChange={handleFileChange}
                fileList={file ? [file] : []}
                maxCount={1}
              >
                <Button icon={<UploadOutlined />}>Select File</Button>
              </Upload>
            </Form.Item>
          </Form>
        </Modal>

        <Tabs
          defaultActiveKey="1"
          items={tabItems.map(({ key, label, slicer }) => ({
            key,
            label,
            children: (
              <div>
                {profiles.filter((profile) => profile.SlicerName === slicer)
                  .length === 0 ? (
                  <Text type="secondary" style={{ fontSize: 24 }}>
                    No profiles found! If you have one, please upload it above.
                  </Text>
                ) : (
                  <Row gutter={[8, 16]}>
                    {profiles
                      .filter((profile) => profile.SlicerName === slicer)
                      .map((profile) => (
                        <Col xs={24} sm={12} md={8} key={profile.id}>
                          <ProfileCard profileData={profile} />
                        </Col>
                      ))}
                  </Row>
                )}
              </div>
            ),
          }))}
        />
      </div>
      <div
        style={{
          margin: "1% 0% 20%",
          padding: "5px 25px 50px",
          backgroundColor: "white",
          boxShadow: "0 0 4px rgba(0,0,0,0.2)",
          borderRadius: 5,
          width: "50%",

          float: "right",
        }}
      >
        <Title level={2} style={{ marginTop: 15 }}>
          Project Images Using Filament
        </Title>

        <Text>
          Find images of other projects using {filament.brandName}{" "}
          {filament.matType}
        </Text>
        <br />
        <br />

        <Button type="primary" disabled onClick={openUpload}>
          Upload Image
        </Button><br>
          
        </br>
        <br></br>
        <Text type="success">Coming Soon!</Text>
      </div>
    </>
  );
};

export default SlicerProfiles;
