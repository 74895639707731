import { Card, Row, Col, Rate } from "antd";
import axios from "axios";
import { downloadProfile } from "../../../Services/api";

const ProfileCard = ({ profileData }) => {
  if (!profileData) {
    return <div>Loading...</div>;
  }

  const handleDownload = async () => {
    const file = await downloadProfile(profileData._id);
    const blob = new Blob([file.data], { type: "application/octet-stream" });
    const downloadUrl = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.download = profileData.FileName;
    document.body.appendChild(link);
    link.click();

    window.URL.revokeObjectURL(downloadUrl);
    document.body.removeChild(link);
  };

  return (
    <Card
      actions={[
        <a key="download" onClick={handleDownload}>
          Download
        </a>,
      ]}
      style={{
        borderRadius: "4px",
        boxShadow: "0 0 2px rgba(0,0,0,0.2)",
        marginBottom: "16px",
      }}
    >
      <h1 style={{ fontSize: "18px", fontWeight: "bold" }}>
        {profileData.ProfileName}
      </h1>

      <p style={{ fontSize: "14px", color: "gray" }}>
        Printer: {profileData.PrinterBrand} {profileData.PrinterModel}
      </p>

      <p style={{ fontSize: "14px", color: "gray" }}>
        Uploaded By: {profileData.userName}
      </p>

      {/* Filename */}
      <p style={{ fontSize: "14px", color: "gray" }}>
        {profileData.Description}
      </p>

      {/* Description */}
      <p style={{ fontSize: "14px", marginBottom: "8px" }}>
        Filename: {profileData.FileName}
      </p>
    </Card>
  );
};

export default ProfileCard;
