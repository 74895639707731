import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Typography,
  Breadcrumb,
  Col,
  Row,
  Tag,
  Descriptions,
  Table,
  Tabs,
  Tooltip,
} from "antd";
import FilamentSwatch from "../FilamentSwatch";
import {
  ExclamationCircleFilled,
  ExclamationCircleOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { getFilament } from "../../Services/api";
import MaterialProps from "../MaterialProps";
import SlicerProfiles from "./Profiles/SlicerProfiles";
import { PLA, ABS, PETG } from "../../material_refs";

const { Title, Text } = Typography;

const FilamentDetail = () => {
  const { id } = useParams();
  const [data, setData] = useState([]);

  useEffect(() => {
    getFilament(id)
      .then((response) => {
        setData(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  if (!data) {
    return <div>Loading...</div>;
  }

  const defaultNote = () => (
    <Tooltip title="Manufacturer specific data not available. Estimated based on material type">
      <ExclamationCircleOutlined style={{ fontSize: 15, color: "red" }} />
    </Tooltip>
  );

  const getDefaultMaterialProps = (material, property) => {
    switch (material) {
      case "PLA":
        return (
          <>
            {defaultNote()} <div style={{ marginRight: 5 }} /> {PLA[property]}
          </>
        );
      case "ABS":
        return (
          <>
            {defaultNote()} <div style={{ marginRight: 5 }} /> {ABS[property]}
          </>
        );
      case "PETG":
        return (
          <>
            {defaultNote()} <div style={{ marginRight: 5 }} /> {PETG[property]}
          </>
        );
      default:
        return <Text type="secondary">Data Not Available</Text>;
    }
  };

  const getChemicalName = (material) => {
    switch (material) {
      case "PLA":
      case "PLA+":
      case "Easy PLA":
      case "PLA Pro":
        return PLA.chemicalName;

      case "ABS":
        return ABS.chemicalName;

      case "PETG":
        return PETG.chemicalName;

      default:
    }
  };

  const basicInfoItems = [
    {
      key: "1",
      label: "Brand Name",
      children: data.brandName,
    },
    {
      key: "2",
      label: "Manufacturer Link",
      children:
        data.oemurl == undefined ? (
          <Text type="secondary">N/A</Text>
        ) : (
          <a href={data.oemurl} target="_blank">
            Manufacturer Link
          </a>
        ),
    },
    {
      key: "3",
      label: "Material",
      children: data.matType,
    },
    {
      key: "4",
      label: "Diameter",
      children: `${data.filamentDiameter} mm`,
    },
    {
      key: "5",
      label: "Diameter Tolerance",
      children: `+/- ${data.tolerance} mm`,
    },
    {
      key: "6",
      label: "Transmission Distance",
      children: <Text type="secondary">N/A</Text>,
    },
  ];

  const filamentDimensions = [];

  const pricingData = [
    {
      key: "1",
      label: "Average Price per Kilogram",
      children:
        data.price == undefined ? (
          <Text type="secondary">Price Not Available</Text>
        ) : (
          `$${data.price}`
        ),
    },
    {
      key: "2",
      label: "Average Price per Meter",
      children:
        data.price == undefined ? (
          <Text type="secondary">Price Not Available</Text>
        ) : (
          `$${parseFloat(data.price / data.filamentLength).toFixed(2)}`
        ),
    },
    {
      key: "3",
      label: "Average Price per Gram",
      children:
        data.price == undefined ? (
          <Text type="secondary">Price Not Available</Text>
        ) : (
          `$${parseFloat(data.price / data.filamentWeight / 1000).toFixed(2)}`
        ),
    },
    {
      key: "4",
      label: "Price per Spool",
      children:
        data.price == undefined ? (
          <Text type="secondary">Price Not Available</Text>
        ) : (
          `$${data.price}`
        ),
    },
    {
      key: "5",
      label: "Amazon",
      children: (
        <a href="https://www.amazon.com/gp/product/B07V1XVXV5">Amazon</a>
      ),
    },
  ];

  const colorFinishInfo = [
    {
      key: "1",
      label: "Manufacturer Color Name",
      children:
        data.filamentFeature == undefined
          ? data.filamentColor
          : data.filamentFeature + " " + data.filamentColor,
    },
    // {
    //   key: "2",
    //   label: "Color Hex Code",
    //   children: <Text type="secondary">Coming Soon</Text>
    // },
    {
      key: "3",
      label: "Filament Swatch",
      children: (
        <FilamentSwatch
          color={String(data.filamentColor)}
          titleSwatch={false}
        />
      ),
    },
    {
      key: "4",
      label: "Texture/Finish",
      children: data.filamentFinish || "Standard",
    },
    {
      key: "5",
      label: "Notes",
      children: (
        <Text type="secondary">
          Color shown here is for example only and may not accurately represent
          actual filament color. Please check with the manufacturer for the most
          accurate color representation.
        </Text>
      ),
    },
  ];

  const temperatureRefInfo = [
    {
      key: "1",
      label: "Heated Bed Required",
      children: data.heatedBed ? (
        <Tag color="green">Yes</Tag>
      ) : (
        <>
          <Tag color="red">No</Tag>
        </>
      ),
    },
    {
      key: "2",
      label: "Enclosure Required",
      children: data.enclosureRequired ? (
        <Tag color="red">No</Tag>
      ) : (
        <Tag color="green">Yes</Tag>
      ),
    },
    {
      key: "3",
      label: "Low Bed Temp",
      children:
        data.lowBedTemp === undefined || data.lowBedTemp === 0
          ? getDefaultMaterialProps(data.matType, "bedLowTemp")
          : `${data.lowBedTemp}°C`,
    },
    {
      key: "4",
      label: "High Bed Temp",
      children:
        data.highBedTemp == undefined
          ? getDefaultMaterialProps(data.matType, "bedHighTemp")
          : `${data.highBedTemp}°C`,
    },
    {
      key: "5",
      label: "Low Nozzle Temp",
      children:
        data.lowPrintTemp == undefined
          ? getDefaultMaterialProps(data.matType, "extruderLowTemp")
          : `${data.lowPrintTemp}°C`,
    },
    {
      key: "6",
      label: "High Nozzle Temp",
      children:
        data.highPrintTemp == undefined
          ? getDefaultMaterialProps(data.matType, "extruderHighTemp")
          : `${data.highPrintTemp}°C`,
    },
    {
      key: "7",
      label: "Notes",
      children: (
        <Text type="secondary">
          Where possible, these temperatures are manufacturer specified. If that
          information is not available, the values are estimated based on the
          material
        </Text>
      ),
    },
  ];

  const packagingInfo = [
    {
      key: "1",
      label: "Spool Material",
      children: data.spoolMaterial,
    },
    {
      key: "2",
      label: "Spool Diameter",
      children: `${data.spoolDiameter} cm`,
    },
    {
      key: "3",
      label: "Spool Empty Weight",
      children: `${data.spoolWeight} g`,
    },
    {
      key: "4",
      label: "Spool Hole Diameter",
      children: `${data.spoolHoleDiameter} cm`,
    },
    {
      key: "5",
      label: "Filament Length On Spool",
      children: `${data.filamentLength} m`,
    },
    {
      key: "6",
      label: "Spool Remaining Indicator",
      children: data.spoolRemainingIndicator ? (
        <Tag color="red">No</Tag>
      ) : (
        <Tag color="green">Yes</Tag>
      ),
    },
  ];

  const materialProps = [
    {
      key: "1",
      label: "Tensile Strength",
      children: data.tensileStrength ? (
        <MaterialProps value={data.tensileStrength} />
      ) : (
        getDefaultMaterialProps(data.matType, "tensileStrength")
      ),
    },
    {
      key: "2",
      label: "Flexural Modulus",
      children: data.flexuralModulus ? (
        <MaterialProps value={data.flexuralModulus} />
      ) : (
        getDefaultMaterialProps(data.matType, "flexuralModulus")
      ),
    },
    {
      key: "3",
      label: "Elongation at Break",
      children: data.elongationAtBreak ? (
        <MaterialProps value={data.elongationAtBreak} />
      ) : (
        getDefaultMaterialProps(data.matType, "elongationBreak")
      ),
    },
    {
      key: "4",
      label: "Flexural Strength",
      children: data.flexuralStrength ? (
        <MaterialProps value={data.flexuralStrength} />
      ) : (
        getDefaultMaterialProps(data.matType, "flexuralStrength")
      ),
    },
    {
      key: "5",
      label: "Impact Strength",
      children: data.impactStrength ? (
        <MaterialProps value={data.impactStrength} />
      ) : (
        getDefaultMaterialProps(data.matType, "impactStrength")
      ),
    },
    {
      key: "6",
      label: "Glass Transition Temp",
      children: data.glassTransitionTemp ? (
        <MaterialProps value={data.glassTransitionTemp} />
      ) : (
        getDefaultMaterialProps(data.matType, "glassTransitionTemp")
      ),
    },
    {
      key: "7",
      label: "Shore Hardness",
      children: data.shoreHardness ? (
        <MaterialProps value={data.shoreHardness} />
      ) : (
        getDefaultMaterialProps(data.matType, "shoreHardness")
      ),
    },
    {
      key: "8",
      label: "Density",
      children: data.density ? (
        <MaterialProps value={data.density} />
      ) : (
        getDefaultMaterialProps(data.matType, "density")
      ),
    },
    {
      key: "9",
      label: "Hygroscopicity",
      children: data.density ? (
        <MaterialProps value={data.density} />
      ) : (
        getDefaultMaterialProps(data.matType, "hygroscopicity")
      ),
    },
    {
      key: "10",
      label: "Notes",
      children: (
        <Text type="secondary">
          Material properties are manufacturer specified where possible. If that
          information is not available, the values are estimated based on the
          material type.
        </Text>
      ),
    },
  ];

  const finishingDetail = [
    {
      key: "1",
      label: "Dissolves In",
      children:
        data.chemicalsDissolve == undefined ? (
          <Text type="secondary">N/A</Text>
        ) : (
          data.chemicalsDissolve
        ),
    },
  ];

  const safetyItems = [
    {
      key: "1",
      label: "Chemical Name",
      children: getChemicalName(data.matType),
    },
    {
      key: "2",
      label: "Safety Data Sheet",
      children:
        data.msdslink == undefined ? (
          <Text type="secondary">N/A</Text>
        ) : (
          <a href={data.msdsurl} target="_blank">
            Safety Data Sheet
          </a>
        ),
    },
    {
      key: "3",
      label: "Toxic Fumes During Printing",
      children: data.toxicFumes ? (
        <Tag color="red">No</Tag>
      ) : (
        <Tag color="green">Yes</Tag>
      ),
    },
    {
      key: "4",
      label: "Food Safe",
      children: data.foodSafe ? (
        <>
          <Tag color="green">Yes</Tag>
          <Text type="danger">
            <strong>NOTE: </strong>"Food safe" in this context implies that the
            chemical the filament is made out of is food safe, but that does not
            necessarily mean that printed parts are food safe due to other
            considerations such as the porosity of printed parts which can
            collect bacteria. Food contact with 3d printed items is not
            recommended.
          </Text>
        </>
      ) : (
        <Tag color="red">No</Tag>
      ),
    },
    {
      key: "5",
      label: "Biodegradable",
      children: data.biodegrade ? (
        <Tag color="green">Yes</Tag>
      ) : (
        <Tag color="red">No</Tag>
      ),
    },
    {
      key: "6",
      label: "Notes",
      children: (
        <Text type="secondary">
          Verify the material you are using is fit for the purpose you wish to
          use it for before using it. The safety information presented here is
          for informational purposes only and should not be relied on if safety
          is a concern.
        </Text>
      ),
    },
  ];

  const titleBrand = data.brandName;
  const titleMatType = data.matType;
  const titleFilamentFinish =
    data.filamentFinish == undefined ? "" : data.filamentFinish;
  const titleFilamentFeature =
    data.filamentFeature == undefined ? "" : data.filamentFeature;
  const titleFilamentColor = data.filamentColor;

  const title = `${titleBrand} - ${titleMatType} - ${titleFilamentFinish} ${titleFilamentFeature} ${titleFilamentColor}`;

  return (
    <>
      <Title level={2}>{title}</Title>
      <FilamentSwatch color={String(data.filamentColor)} titleSwatch={true} />
      <Breadcrumb style={{ fontSize: 18, marginTop: 15 }}>
        <Breadcrumb.Item href="/">Filament Table</Breadcrumb.Item>
        <Breadcrumb.Item>
          {data.brandName} {data.matType} {data.filamentFinish}
          {data.filamentFeature} {data.filamentColor}
        </Breadcrumb.Item>
      </Breadcrumb>
      <div
        style={{
          margin: "1% 0% 0%",
          padding: "1px 25px 30px",
          backgroundColor: "white",
          boxShadow: "0 0 2px rgba(0,0,0,0.4)",
          borderRadius: 5,
        }}
      >
        <Title level={2} style={{ marginTop: 15 }}>
          Filament Details
        </Title>
        <Row style={{ marginTop: 20 }} gutter={10}>
          <Col sm={24} md={18} lg={6} xl={6}>
            <Descriptions
              title="Filament Manufacturer/Dimensions"
              items={basicInfoItems}
              column={1}
            />
          </Col>

          <Col sm={24} md={18} lg={6} xl={6}>
            <Descriptions
              title="Color and Finish Information"
              column={1}
              items={colorFinishInfo}
            />
          </Col>
          <Col sm={24} md={18} lg={6} xl={6}>
            <Descriptions title="Safety Data" column={1} items={safetyItems} />
          </Col>
          <Col sm={24} md={18} lg={6} xl={6}>
            <Descriptions title="Pricing Data" column={1} items={pricingData} />
          </Col>
        </Row>

        <Row style={{ marginTop: 100 }}>
          <Col sm={24} md={18} lg={6} xl={6}>
            <Descriptions
              title="Temperature Reference"
              column={1}
              items={temperatureRefInfo}
            />
          </Col>

          <Col sm={24} md={18} lg={6} xl={6}>
            <Descriptions
              title="Spool Information"
              column={1}
              items={packagingInfo}
            />
          </Col>

          <Col sm={24} md={18} lg={6} xl={6}>
            <Descriptions
              title="Material Properties"
              column={1}
              items={materialProps}
            />
          </Col>
          <Col sm={24} md={18} lg={6} xl={6}>
            <Descriptions
              title="Finishing Detail"
              column={1}
              items={finishingDetail}
            ></Descriptions>
          </Col>
        </Row>
      </div>

      <SlicerProfiles filament={data} />
    </>
  );
};

export default FilamentDetail;
