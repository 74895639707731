export const PLA = {
  chemicalName: "Polylactic Acid",
  tensileStrength: "38 - 47.8 MPa",
  tensileModulus: "~4200 MPa",
  elongationBreak: "41.3 - 63.8%",
  flexuralStrength: "85 MPa",
  flexuralModulus: "3280 MPa",
  glassTransitionTemp: "53°C",
  impactStrength: "7 kJ/m²",
  shoreHardness: "D 66",
  density: "1.08 - 1.12 g/cm³",
  extruderLowTemp: "220 °C",
  extruderHighTemp: "240 °C",
  bedLowTemp: "100 °C",
  bedHighTemp: "130 °C",
  hygroscopicity: "0.5 - 1.0%",
};

export const ABS = {
  chemicalName: "Acrylonitrile Butadiene Styrene",
  tensileStrength: "29.6 - 48 MPa",
  tensileModulus: "~1790 - 3200 MPa",
  elongationBreak: "53%",
  flexuralStrength: "60.6 - 73.1 MPa",
  flexuralModulus: "1600 - 2400 MPa",
  glassTransitionTemp: "108 °C",
  impactStrength: "7 kJ/m²",
  shoreHardness: "D 100",
  density: "1.01 - 1.2 g/cm³",
  extruderLowTemp: "220 °C",
  extruderHighTemp: "240 °C",
  bedLowTemp: "100 °C",
  bedHighTemp: "130 °C",
};

export const PETG = {
  chemicalName: "Polyethylene Terephthalate Glycol",
  tensileStrength: "31.9 +/- 1 MPa",
  tensileModulus: "~1472 +/- 270 MPa",
  elongationBreak: "53%",
  flexuralStrength: "60.6 - 73.1 MPa",
  flexuralModulus: "1600 - 2400 MPa",
  glassTransitionTemp: "108 °C",
  impactStrength: "7 kJ/m²",
  shoreHardness: "D 100",
  density: "1.25 g/cm³ @ 21°C",
  extruderLowTemp: "230 °C",
  extruderHighTemp: "240 °C",
  bedLowTemp: "70 °C",
  bedHighTemp: "80 °C",
};

