import { Card, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import FilamentSwatch from "../FilamentSwatch";
import "./FilamentCard.css";
import { ArrowRightOutlined } from "@ant-design/icons";

const { Text } = Typography;

const FilamentCard = ({ cardData }) => {
  const navigate = useNavigate();
  const { Meta } = Card;

  const handleLinkToDetail = (id) => {
    navigate("/filament/" + id);
  };

  return (
    <Card
      className="filament-card"
      hoverable={true}
      onClick={() => handleLinkToDetail(cardData._id)}
    >
      <Meta
        title={
          <span className="filament-card-title">
            {cardData.brandName +
              " " +
              (cardData.filamentFeature == undefined
                ? ""
                : cardData.filamentFeature) +
              " " +
              cardData.filamentColor +
              " " +
              cardData.matType}{" "}
          </span>
        }
        avatar={
          <FilamentSwatch
            color={cardData.filamentColor}
            tableCardSwatch={true}
          />
        }
      />
      <span className="filament-card-details">
        Diameter:{" "}
        {cardData.filamentDiameter}mm • High Bed Temp: {cardData.highBedTemp}°C
        • High Nozzle Temp:
        {cardData.highPrintTemp}°C • Diameter Tolerance: +/-{" "}
        {cardData.tolerance}mm
      </span>
    </Card>
  );
};

export default FilamentCard;
