import { useState, useEffect, useCallback } from "react";
import { Form, Typography, Input, Select, Row, Col, Space } from "antd";
import "./FilamentTableFilter.css"; // Import custom styles
import _ from "lodash";

const FilamentTableFilter = ({ data, setFilteredData }) => {
  const { Title } = Typography;
  const [search, setSearch] = useState("");

  const { Option } = Select;

  useEffect(() => {
    // Filter data based on search term
    const filtered = data.filter((item) =>
      Object.values(item).some((field) =>
        field.toString().toLowerCase().includes(search)
      )
    );
    setFilteredData(filtered);
  }, [search, data, setFilteredData]);

  const handleSearchChange = useCallback(
    _.debounce((e) => {
      setSearch(e.target.value.toLowerCase());
    }),
    [300]
  );

  const handleSortChange = (value) => {
    const sorted = [...data].sort((a, b) => {
      switch (value) {
        case "highestBedTemp":
          return b.highBedTemp - a.highBedTemp;
        case "lowestBedTemp":
          return a.highBedTemp - b.highBedTemp;
        case "highestNozzleTemp":
          return b.highPrintTemp - a.highPrintTemp;
        case "lowestNozzleTemp":
          return a.highPrintTemp - b.highPrintTemp;
        case "largestDiameter":
          return b.filamentDiameter - a.filamentDiameter;
        case "smallestDiameter":
          return a.filamentDiameter - b.filamentDiameter;
        default:
          return 0;
      }
    });
    setFilteredData(sorted);
  };

  return (
    <>
      <Title level={3} style={{ color: "#666666" }}>
        Filter and Sort
      </Title>
      <Form layout="vertical" className="filter-form">
        <Row gutter={[16, 16]}>
          <Col xs={24} md={16}>
            <Form.Item label="Search" name="search">
              <Input
                value={search}
                size="large"
                onChange={handleSearchChange}
                placeholder="Type to search for brand, color, diameter, material type, etc..."
                allowClear
              />
            </Form.Item>
            <Form.Item>
              <Space>
                <span>Sort by:</span>
                <br></br>
                <Select
                  defaultValue="Sort"
                  style={{ width: 120 }}
                  onChange={handleSortChange}
                >
                  <Option value="highestBedTemp">Highest Bed Temp</Option>
                  <Option value="lowestBedTemp">Lowest Bed Temp</Option>
                  <Option value="highestNozzleTemp">Highest Nozzle Temp</Option>
                  <Option value="lowestNozzleTemp">Lowest Nozzle Temp</Option>
                  <Option value="largestDiameter">Largest Diameter</Option>
                  <Option value="smallestDiameter">Smallest Diameter</Option>
                </Select>
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default FilamentTableFilter;
