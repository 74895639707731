import { Outlet, Link } from "react-router-dom";
import { Layout, Menu, Typography, Image, Avatar, Dropdown } from "antd";
import { Footer } from "antd/es/layout/layout";

const { Header, Content } = Layout;
const { Text, Title } = Typography;

const MyLayout = () => {
  const avatarMenu = [
    {
      key: "1",
      label: <Link to="/login">Login</Link>,
    },
    {
      key: "2",
      label: <Link to="/profile">Profile</Link>,
    },
  ];

  const menuItems = [
    {
      label: "Filament",
      key: "1",
      link: "/",
      style: { fontSize: 18, fontFamily: "Fira Code", marginLeft: 10 },
    },
  ];

  return (
    <Layout>
      <Header
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingLeft: 10,
          paddingBottom: 0,
          paddingRight: 20,
          boxShadow: "0 2px 2px 2 rgba(0, 0, 0, 0.3)",
        }}
      >
        <Text
          style={{
            color: "white",
            fontFamily: "Fira Code",
            alignContent: "center",
            fontSize: 23,
            fontWeight: "bold",
            letterSpacing: 2,
          }}
        >
          3dFilaments.info
        </Text>
        <Menu
          items={menuItems}
          mode="horizontal"
          theme="dark"
          style={{ flex: 1, minWidth: 0 }}
        ></Menu>
        <Avatar
          style={{
            height: 40,
            width: 40,
            float: "right",
            paddingTop: 0,
            backgroundColor: "white",
            marginTop: 10,
          }}
        />
      </Header>
      <Content style={{ backgroundColor: "#eeeeee", padding: "20px" }}>
        <Outlet />
      </Content>
      <Footer style={{ textAlign: "center" }}>
        3dfilaments.tech ©{new Date().getFullYear()}
      </Footer>
    </Layout>
  );
};

export default MyLayout;
