const ColorMap = (color) => {
  if (colorMap[color] === undefined) {
    return color;
  }

  return colorMap[color];
};

const colorMap = {
  "Peacock Blue": "#00A5CF",
  "Pastel Green": "#A7C947",
  "Neon Green": "#39FF14",
  Mint: "#3EB489",
  "Midnight Purple": "#4E5180",
  "Lime Green": "#32CD32",
  "Light Purple": "#BF77F6",
  "Light Orange": "#FFA07A",
  "Light Blue": "#ADD8E6",
  "Iron Red": "#E60033",
  "Forest Green": "#228B22",
  "Dark Yellow": "#FFD700",
  "Dark Blue": "#00008B",
  "Ash Gray": "#B2BEB5",
  "Mint Green": "#98FF98",
  "Carbon Fiber": "#333333",
  Copper: "#B87333",
  Flesh: "#FF7D40",
  "Gray Blue": "#6B8E23",
  "Stone Gray": "#928C8C",
  Terracotta: "#E2725B",
  Rust: "#B7410E",
  "Baby Blue": "#89CFF0",
  "Robin Egg": "#00CCCC",
  "Light Periwinkle": "#C5C9C7",
  Beige: "#F5F5DC",
  "Baby Pink": "#F4C2C2",
  Bronze: "#CD7F32",
  "Olive Green": "#B5B35C",
  "Neon Red": "#FF1818",
  "Dark Raspberry": "#872657",
  "Light Lavender": "#E6E6FA",
  "Seafoam Blue": "#78B7B7",
  "Sea Breeze": "#A5DDE5",
  Lemonade: "#FFD700",
  "Cool Mint": "#A1D8CD",
  Mustard: "#FFDB58",
  "Jet Black": "#000000",
  "Chalky Blue": "#8A2BE2",
  "Ocean Blue": "#4F42B5",
  "Jungle Green": "#29AB87",
  "Pistachio Green": "#93C572",
  "Prusa Galaxy Black": "#000000",
  "Matte Black": "#000000",
  "Prusa Orange": "#FF6600",
  "Gravity Gray": "#808080",
  "Anthracite Gray": "#464646",
  "Terracotta Light": "#E2725B",
  "Ultramarine Blue": "#4166F5",
  "Lipstick Red": "#ff0000",
  "Yellow Gold": "#FFD700",
  "Urban Gray": "#808080",
  "Mango Yellow": "#FFD700",
  "Signal White": "#FFFFFF",
  "Azure Blue": "#007FFF",
  "Ms Pink": "#FFC0CB",
  "Pineapple Yellow": "#FFD700",
  "Radiant Sky": "#87CEEB",
  Ivory: "#FFFFF0",
  "My Silverness": "#C0C0C0",
  "Premium Mystic Brown": "#8B4513",
  "Premium Mystic Green": "#228B22",
  "Carmine Red": "#960018",
};

export default ColorMap;
