import React, { useEffect, useState } from "react";
import { Message, Card } from "antd";

import { getFilaments } from "../Services/api";
import FilamentTable from "./FilamentTable/FilamentTable";
import FilamentTableFilter from "./FilamentTable/FilamentTableFilter";

const FilamentIndex = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    getFilaments()
      .then((response) => {
        setData(response);
        setFilteredData(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  if (!data) {
    return <div>Loading ...</div>;
  }

  return (
    <>
      <div className="container-margin-left">
        <Card style={{ fontSize: 18, boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.2)" }}>
          Welcome! This site serves as a sortable and searchable aggregated index of 3d
          printer filaments from various manufacturers and includes data on
          filaments such as bed and nozzle temperatures, tolerances, spool
          parameters, lengths, weights, etc. You can also upload slicer profiles for each filament, helping other
          hobbyists/makers get the best results from their prints. 
        </Card>

        <FilamentTable
          data={data}
          filteredData={filteredData}
          setFilteredData={setFilteredData}
        />
      </div>
    </>
  );
};

export default FilamentIndex;
