import axios from "axios";

let api = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? `http://${window.location.hostname}:8080/api`
      : `https://${window.location.hostname}/api`,
});

export const getFilaments = async () => {
  const response = await api.get(`/filaments`);
  return response.data;
};

export const getFilament = async (id) => {
  const response = await api.get(`/filaments/${id}`);
  return response.data;
};

export const getFilamentProfiles = async () => {
  const response = await api.get(`/filamentProfiles`);
  return response.data;
};

export const getPrinters = async () => {
  const response = await api.get(`/printers`);
  return response.data;
};

export const fetchProfiles = async (filament_id) => {
  try {
    const response = await api.get(`filamentProfilesByFilament/${filament_id}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching profiles:", error);
  }
};

export const uploadProfile = async (formData) => {
  try {
    const response = await api.post(`filamentProfiles`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.status;
  } catch (error) {
    console.log(error);
  }
};

export const downloadProfile = async (id) => {
  try {
    const response = await api.get(`filamentProfiles/download/${id}`, {
      responseType: "blob",
    });

    return response;
  } catch (error) {
    console.error("Error downloading profile:", error);
  }
};
