import { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { useMediaQuery } from "react-responsive";
import "./FilamentTable.css";
import FilamentSwatch from "../FilamentSwatch";
import FilamentCard from "./FilamentCard";
import FilamentTableFilter from "./FilamentTableFilter";
import {
  Table,
  Input,
  Typography,
  Alert,
  Space,
  Col,
  Row,
  Pagination,
  Form,
} from "antd";

const _ = require("lodash");
const { Text } = Typography;

const FilamentTable = ({ data, filteredData, setFilteredData }) => {
  const navigate = useNavigate();
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });
  const [filteredInfo, setFilteredInfo] = useState({});

  const isSmallScreen = useMediaQuery({ query: "(max-width:1280px)" });

  const handleSearch = useCallback(
    _.debounce((e) => {
      const searchValue = e.target.value.toLowerCase();
      const filtered = data.filter((entry) => {
        return Object.keys(entry).some((key) => {
          if (entry[key] != null) {
            return entry[key].toString().toLowerCase().includes(searchValue);
          }
          return false;
        });
      });
      setFilteredData(filtered);
    }, 300),
    [data]
  );
  
  const handleSort = (column) => {
    const newDirection =
      sortConfig.direction === "ascending" ? "descending" : "ascending";

    const sortedData = [...filteredData].sort((a, b) => {
      const valueA = a[column];
      const valueB = b[column];

      const parseValue = (value) => {
        if (typeof value === "string" && value.includes("$")) {
          return parseFloat(value.replace(/\$/g, ""));
        }
        if (
          typeof value === "string" &&
          value.includes("°") &&
          value.includes("C")
        ) {
          return parseInt(value.replace(/°|C/g, "").trim(), 10);
        }
        return value;
      };

      const parsedA = parseValue(valueA);
      const parsedB = parseValue(valueB);

      if (!isNaN(parsedA) && !isNaN(parsedB)) {
        return newDirection === "ascending"
          ? parsedA - parsedB
          : parsedB - parsedA;
      }
      if (typeof parsedA === "boolean" || typeof parsedB === "boolean") {
        return newDirection === "ascending"
          ? (parsedA === true ? 1 : -1) - (parsedB === true ? 1 : -1)
          : (parsedB === true ? 1 : -1) - (parsedA === true ? 1 : -1);
      }

      if (parsedA < parsedB) return newDirection === "ascending" ? -1 : 1;
      if (parsedA > parsedB) return newDirection === "ascending" ? 1 : -1;
      return 0;
    });

    setSortConfig({ key: column, direction: newDirection });
    setFilteredData(sortedData);
  };

  const handleFilter = (pagination, filters) => {
    setFilteredInfo(filters);
  };

  const columns = [
    {
      index: "1",
      title: "Brand",
      dataIndex: "brandName",
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.brandName.localeCompare(b.brandName),
    },
    {
      index: "2",
      title: "Material Type",
      dataIndex: "matType",
      sorter: (a, b) => a.matType.localeCompare(b.matType),
    },
    {
      index: "4",
      title: "Color Name",
      dataIndex: "filamentColor",
      sorter: (a, b) => a.filamentColor.localeCompare(b.filamentColor),
    },
    {
      index: "5",
      title: "Finish/Special Feature",
      dataIndex: "filamentFinish",
      sorter: (a, b) => a.filamentFinish.localeCompare(b.filamentFinish),
    },
    {
      index: "6",
      title: "Color Swatch",
      dataIndex: "filamentColor",
      render: (color) => (
        <>
          <FilamentSwatch color={color} tableCardSwatch={true} />
        </>
      ),
    },
    {
      index: "7",
      title: "Avg Price",
      dataIndex: "price",
      render: (price) =>
        price == undefined ? (
          <Text type="secondary">No Price Available</Text>
        ) : (
          <>${price}</>
        ),
      sorter: (a, b) => {
        if (a.price == undefined) {
          return 0 - b.price;
        }
        if (b.price == undefined) {
          return a.price - 0;
        }

        return a.price - b.price;
      },
    },
    {
      index: "8",
      title: "Diameter",
      dataIndex: "filamentDiameter",
      render: (diameter) => <>{diameter} mm</>,
      sorter: (a, b) => a.filamentDiameter - b.filamentDiameter,
    },
    {
      index: "9",
      title: "Diameter Tolerance",
      dataIndex: "tolerance",
      render: (tolerance) => <>+/- {tolerance} mm</>,
      sorter: (a, b) => a.tolerance - b.tolerance,
    },
    {
      index: "10",
      title: "Low Bed Temp",
      dataIndex: "lowBedTemp",
      render: (temp) => <>{temp} °C</>,
      sorter: (a, b) => a.lowBedTemp - b.lowBedTemp,
    },
    {
      index: "11",
      title: "High Bed Temp",
      dataIndex: "highBedTemp",
      render: (temp) => <>{temp} °C</>,
      sorter: (a, b) => a.highBedTemp - b.highBedTemp,
    },

    {
      index: "12",
      title: "Low Nozzle Temp",
      dataIndex: "lowPrintTemp",
      render: (temp) => <>{temp} °C</>,
      sorter: (a, b) => a.lowPrintTemp - b.lowPrintTemp,
    },
    {
      index: "13",
      title: "High Nozzle Temp",
      dataIndex: "highPrintTemp",
      render: (temp) => <>{temp} °C</>,
      sorter: (a, b) => a.highPrintTemp - b.highPrintTemp,
    },
    {
      index: "14",
      title: "Heated Bed Req.",
      dataIndex: "heatedBed",
      render: (heatedBed) => <>{heatedBed === true ? <>Yes</> : <>No</>}</>,
      filters: [
        { text: "Yes", value: "Yes" },
        { text: "No", value: "No" },
      ],
      onFilter: (value, record) => (record.heatedBed ? "Yes" : "No") === value,
    },
    {
      key: "15",
      title: "Spool Material",
      dataIndex: "spoolMaterial",
      filters: [
        { text: "Plastic", value: "Plastic" },
        { text: "Cardboard", value: "Cardboard" },
      ],
      onFilter: (value, record) => record.spoolMaterial.indexOf(value) === 0,
    },
    {
      key: "16",
      title: "Spool Diameter",
      dataIndex: "spoolDiameter",
      render: (spoolDiameter) => <>{spoolDiameter} cm</>,
      sorter: (a, b) => a.spoolDiameter - b.spoolDiameter,
    },
    {
      key: "17",
      title: "Empty Spool Weight",
      dataIndex: "spoolWeight",
      render: (spoolWeight) => <>{spoolWeight} g</>,
      sorter: (a, b) => a.spoolWeight - b.spoolWeight,
    },
  ];

  return (
    <>
      {isSmallScreen ? (
        <>
          <FilamentTableFilter data={data} setFilteredData={setFilteredData} />
          <Row gutter={[8]}>
            {filteredData.map((cardData) => (
              <Col xs={24} sm={24} md={24} lg={12} key={cardData._id}>
                <FilamentCard cardData={cardData} />
              </Col>
            ))}
          </Row>
        </>
      ) : (
        <>
          <Form style={{ marginTop: 20 }}>
            <Row gutter={[16, 16]}>
              <Col xs={24} md={16}>
                <Form.Item label="Search" name="search">
                  <Input
                    size="large"
                    placeholder="Type to search for brand, color, diameter, material type, etc..."
                    allowClear
                    onChange={handleSearch}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
          {filteredData.length === 0 && (
            <Alert
              message="No Results Found"
              description="Please try a different search term or filter."
              type="warning"
              showIcon
            />
          )}
          <Text type="secondary">HINT: Rows are clickable for more detail</Text>
          <Table
            columns={columns}
            dataSource={filteredData}
            style={{
              marginTop: 10,
              boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.3)",
            }}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => navigate("/filament/" + record._id),
              };
            }}
            rowHoverable={true}
            pagination={{ position: ["bottomCenter"], pageSize: 75 }}
            onPaginationChange={handleFilter}
            onSorterChange={handleSort}
            rowClassName="table-row"
            scroll={{ y: 1500 }}
            showSorterTooltip={true}
          />
        </>
      )}
    </>
  );
};

export default FilamentTable;
